import { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'

import formatPrice from '../../functions/formatPrice'
import { CartContext } from '../cart/CartContext'
import QuantitySelector from '../QuantitySelector'
import IconRemove from '../../icons/Remove'

const Card = styled.div`
  display: flex;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
`

const Image = styled.img`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 5px;
  background-color: var(--gray5);
  text-indent: -99999px;
`

const Content = styled.div`
  padding: 0 0.5rem;
  display: grid;
  grid-row-gap: 0.25rem;
  flex-grow: 1;
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: -0.25em;
  font-size: 1rem;
`

const Note = styled.p`
  margin: 0;
  font-size: 0.9rem;
  color: var(--gray3);
`

const Price = styled.p`
  margin: 0;
  color: var(--gray4);
`

const Controls = styled.div`
  display: flex;
  align-items: center;

  button {
    all: unset;
    font-family: inherit;
    font-size: 0.8em;
    color: var(--tp-coral);
    margin-left: auto;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
  }
`

export default function CartItem({ product }) {
  const { slug, image, title, price, qtd, obs } = product
  const { cart, setCart } = useContext(CartContext)

  // update product quantity
  const [quantity, setQuantity] = useState(qtd)
  useEffect(() => {
    const cartModified = cart.map((product) => {
      if (product.slug === slug) product.qtd = quantity
      return product
    })
    setCart(cartModified)
    // eslint-disable-next-line
  }, [quantity])

  function removeItemFromCart() {
    const newCart = cart.filter((product) => product.slug !== slug)
    setCart(newCart)
  }

  return (
    <Card key={slug}>
      <Image src={image} alt="" />
      <Content>
        <Title>{title}</Title>
        <Price>{formatPrice(price)}</Price>
        <Controls>
          <QuantitySelector quantity={quantity} setQuantity={setQuantity} />
          <span>{formatPrice(price, qtd)}</span>
          <button onClick={removeItemFromCart}>
            <IconRemove size="1rem" style={{ fill: 'var(--tp-coral)' }} />
          </button>
        </Controls>
        {obs && (
          <Note>
            <strong>Observações:</strong> {obs}
          </Note>
        )}
      </Content>
    </Card>
  )
}
