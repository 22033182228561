import { useLocation, useNavigate } from 'react-router-dom'

/**
 * Custom hook to navigate to previous page with fallback to a specific page if have nothing to go back
 * @param {string} safePath Optional (default: "/")
 */
export default function useBackSafe(safePath = '/') {
  const navigate = useNavigate()
  const location = useLocation()
  const hasPreviousState = location.key !== 'default'
  const goBackSafe = () =>
    hasPreviousState ? navigate(-1) : navigate(safePath)

  return { goBackSafe, hasPreviousState }
}
