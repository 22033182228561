import styled from 'styled-components'

export const Form = styled.form`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
  padding-bottom: 1rem;

  label {
    font-weight: 700;
    display: flex;
    flex-direction: column;
    div {
      font-weight: 400;
    }
  }
`

export const InputStyled = styled.input`
  width: 100%;
  margin-top: 0.25em;
  font-family: inherit;
  font-size: inherit;
  border-radius: var(--radius-sm);
  background-color: var(--gray6);
  padding: 0.5em;
  border: ${(props) => (props.error ? '1px solid #eb5757' : '1px solid #e0e0e0')};
  box-shadow: ${(props) =>
    props.error ? 'inset 0 0 0 1px #eb5757' : 'inset 0 0 0 0 transparent'};
  transition: box-shadow 0.2s ease, border 0.2s ease;

  :focus {
    outline: none;
    box-shadow: inset 0 0 0 1px var(--tp-marrom);
  }

  :disabled {
    opacity: 0.6;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ col = 1 }) => `repeat(${col}, 1fr)`};
  grid-gap: 0.5rem;
`

export const Action = styled.button`
  border: none;
  font-size: 0.9rem;
  display: block;
  padding: 0.5rem;
  border-radius: var(--radius-sm);
  color: white;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: unset;
  }
`
