import { Link } from 'react-router-dom'
import styled from 'styled-components'

import formatPrice from '../../functions/formatPrice'

const Card = styled(Link)`
  margin: 1rem;
  display: flex;
  color: inherit;
  text-decoration: none;
`

const Image = styled.img`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 5px;
  background-color: var(--gray5);
  text-indent: -99999px;
  cursor: pointer;
`

const Content = styled.div`
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.1rem;
`

const Description = styled.p`
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: var(--gray3);
`

const Price = styled.p`
  margin: 0;
  margin-top: auto;
  color: var(--tp-verde);
  font-size: 0.95rem;
  /* font-weight: bold; */
`

export default function Product({ product }) {
  const { _productId, image, slug, title, excerpt, price, amount } = product

  return (
    <Card key={_productId} to={`produto/${slug}`}>
      <Image src={image} alt="" />
      <Content>
        <Title>{title}</Title>
        <Description>{excerpt}</Description>
        <Price>
          {formatPrice(price)} – {amount}
        </Price>
      </Content>
    </Card>
  )
}
