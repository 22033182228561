import { useEffect } from 'react'

/**
 * Custom hook that takes a ref and a callback to handle the click event outside the ref element
 * @param {object} ref
 * @param {function} callback
 */
export function useClickOutside(ref, callback) {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}
