import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { CartContext } from '../cart/CartContext'
import formatPrice from '../../functions/formatPrice'
import QuantitySelector from '../QuantitySelector'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: var(--bk);
  padding: 1rem;
  width: 100%;
  max-width: var(--max-width);
  border-top: 1px solid var(--gray5);

  display: flex;
`

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  border: none;
  font-size: inherit;
  box-shadow: none;

  padding: 0.75rem;
  background-color: var(--tp-verde);
  color: white;
  border-radius: var(--radius);
  cursor: pointer;

  > :first-child {
    margin-right: 0.5rem;
  }
`

export default function AddCart({ product, notes }) {
  const { slug, image, title, price, amount } = product

  const navigate = useNavigate()
  const [quantity, setQuantity] = useState(1)
  const { cart, setCart } = useContext(CartContext)

  function handleCart() {
    console.log('adicionou à sacola:', slug)

    const alreadyOnCart = cart.find((cartProduct) => cartProduct.slug === slug)

    if (alreadyOnCart) {
      const cartModified = cart.map((cartProduct) => {
        if (cartProduct.slug === slug) cartProduct.qtd += quantity
        return cartProduct
      })
      setCart(cartModified)
    } else {
      setCart((cart) => [
        ...cart,
        {
          slug,
          image,
          title,
          amount,
          price,
          qtd: quantity,
          obs: notes,
        },
      ])
    }

    navigate(-1)
  }

  return (
    <Wrapper>
      <QuantitySelector quantity={quantity} setQuantity={setQuantity} />
      <Button onClick={handleCart}>
        <span>Adicionar</span>
        <span>{formatPrice(price, quantity)}</span>
      </Button>
    </Wrapper>
  )
}
