import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AuthProvider } from './components/common/AuthContext'
import { CartProvider } from './components/cart/CartContext'
import { DataProvider } from './components/common/DataContext'

import Home from './components/home/Home'
import ProductRouting from './components/product/ProductRouting'
import CartPage from './components/cart/CartPage'
import LoginWall from './components/LoginWall'

/*
TODO

 FRONT
  - tela de boas vindas
  - FAQ antes de enviar pro zap tirar dúvidas

 ADMIN
  - alterar o nome da seção não muda o sectionRef dos produtos com o nome da mesma
    > isso vai dar bug uma hora ou outra, o melhor é nem usar esse campo...
    > na real parece ok, se mudar o nome da seção os produtos dela só perdem a referência de quem são, daí não dá de editar sem por de volta em quem pertencem
  - adicionar opção de ocultar produtos e seções

  - Melhorar UX:
    - upload e troca de imagens
    - ordenação de itens e seções (arrastando)
    - destacar item pela lista

 FIREBASE
  - verificação de nomes de produtos, seções e slug repetidos
    > slug ok mais ou menos
    > slug tudo errado... não dá de por como validação de se slug existe pq ao mover de seção por um momento ficam 2 slugs

*/

export default function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <CartProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/produto/*" element={<ProductRouting />} />
              <Route path="/sacola" element={<CartPage />} />
              <Route path="/admin/*" element={<LoginWall />} />
            </Routes>
          </BrowserRouter>
        </CartProvider>
      </DataProvider>
    </AuthProvider>
  )
}
