/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */

import { useState, useEffect } from 'react'

export function useScroll(ms = 25) {
  const [lastScrollTop, setLastScrollTop] = useState(0)
  const [bodyOffset, setBodyOffset] = useState(document.body.getBoundingClientRect())
  const [scrollY, setScrollY] = useState(bodyOffset.top)
  const [scrollX, setScrollX] = useState(bodyOffset.left)
  const [scrollDirection, setScrollDirection] = useState()

  const listener = () => {
    const rect = document.body.getBoundingClientRect()
    setBodyOffset(rect)
    setScrollY(-rect.top)
    setScrollX(rect.left)
    setScrollDirection(lastScrollTop > -rect.top ? 'down' : 'up')
    setLastScrollTop(-rect.top)
  }

  function debounce(func, wait, immediate) {
    var timeout
    return function () {
      var context = this,
        args = arguments
      var later = function () {
        timeout = null
        if (!immediate) func.apply(context, args)
      }
      var callNow = immediate && !timeout
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
      if (callNow) func.apply(context, args)
    }
  }

  var listenerDebounced = debounce(function () {
    listener()
  }, ms)

  useEffect(() => {
    window.addEventListener('scroll', listenerDebounced)
    return () => {
      window.removeEventListener('scroll', listenerDebounced)
    }
    // eslint-disable-next-line
  }, [])

  return {
    scrollY,
    scrollX,
    scrollDirection,
  }
}
