import { createContext, useState } from 'react'

export const CartContext = createContext()

export function CartProvider({ children }) {
  const [cart, setCart] = useState([])
  const [addressIndex, setAddressIndex] = useState(0)

  return (
    <CartContext.Provider value={{ cart, setCart, addressIndex, setAddressIndex }}>
      {children}
    </CartContext.Provider>
  )
}
