import styled from 'styled-components'

import IconMinus from '../icons/Minus'
import IconPlus from '../icons/Plus'

const Quantity = styled.div`
  color: var(--gray3);
  border: 2px solid;
  width: clamp(80px, 30%, 120px);
  margin-right: 0.5rem;
  border-radius: var(--radius);

  display: grid;
  grid-template-columns: repeat(3, minmax(1.5rem, 1fr));

  span {
    font-size: 1.5rem;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
  }

  div {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0;
  }
`

export default function QuantitySelector({
  quantity,
  setQuantity,
  style = {},
}) {
  function handleQuantity(sign) {
    if (sign === '-' && quantity > 1) setQuantity((qtd) => +qtd - 1)
    if (sign === '+') setQuantity((qtd) => +qtd + 1)
  }

  return (
    <Quantity style={style}>
      <span onClick={() => handleQuantity('-')}>
        <IconMinus size="0.5rem" style={{ fill: 'var(--gray3)' }} />
      </span>
      <div>{quantity}</div>
      <span onClick={() => handleQuantity('+')}>
        <IconPlus size="0.5rem" style={{ fill: 'var(--gray3)' }} />
      </span>
    </Quantity>
  )
}
