import firebase from 'firebase/app'
import 'firebase/firestore'
import '@firebase/storage'
import 'firebase/auth'

/**
 * Initialize App
 */
const firebaseConfig = {
  apiKey: 'AIzaSyB3scEaD67VAJF1RHSb8uR9AxHyFt6k1s4',
  authDomain: 'tradicoes-e-paezinhos.firebaseapp.com',
  projectId: 'tradicoes-e-paezinhos',
  storageBucket: 'tradicoes-e-paezinhos.appspot.com',
  messagingSenderId: '788056018347',
  appId: '1:788056018347:web:b78da785edba73c594d6ee',
}
const initializeFirebaseApp = () =>
  !firebase?.apps?.length ? firebase.initializeApp(firebaseConfig) : firebase.app()
export const app = initializeFirebaseApp()

/**
 * Firebase Storage
 */
export const firebaseStorage = firebase.storage()

/**
 * Firebase Firestore database
 */
export const db = firebase.firestore()

/*
 *
 *
------ User
*/

/**
 * Create a new user if not exists
 * @param {Object} user Firebase user object
 */
export const createUser = async (user) => {
  const userSnapshot = await db
    .collection('users')
    .doc(user.uid)
    .get()
    .catch((e) => console.log('error get user:', e))

  if (!userSnapshot.exists) {
    await db
      .collection('users')
      .doc(user.uid)
      .set(
        {
          _uid: user.uid,
          name: user.displayName,
          email: user.email,
        },
        { merge: true }
      )
      .catch((e) => console.log('error new user:', e))
  }
}

/**
 * Observe user
 * @param {Object} user Firebase User object
 * @param {Function} callback
 */
export const observeUser = async (user, callback) => {
  const unsubscribeUser = db
    .collection('users')
    .doc(user.uid)
    .onSnapshot((doc) => callback(doc.data()))

  return unsubscribeUser
}

/**
 * Update user name and tel
 * @param {Object} user Firebase User object
 * @param {Object} data Form data
 */
export const updateUserPersonalInfo = async (user, data) => {
  if (user?.uid) {
    await db.collection('users').doc(user.uid).set(
      {
        name: data.name,
        tel: data.tel,
      },
      { merge: true }
    )
  }
}

/**
 * Add Adress
 * @param {Object} user Firebase user object
 * @param {Object} address address to be added
 * @object {fullAddress}
 */
export const addUserAddress = async (user, data) => {
  const { fullAddress } = data

  if (user?.uid) {
    await db
      .collection('users')
      .doc(user.uid)
      .update({
        fullAddress: firebase.firestore.FieldValue.arrayUnion(fullAddress),
      })
  }
}

/**
 * Remove Adress
 * @param {Object} user Firebase user object
 * @param {Object} address address to be removed
 * @object {fullAddress}
 */
export const removeUserAddress = async (user, data) => {
  const { fullAddress } = data

  if (user?.uid) {
    await db
      .collection('users')
      .doc(user.uid)
      .update({
        fullAddress: firebase.firestore.FieldValue.arrayRemove(fullAddress),
      })
  }
}

/*
 *
 *
------ Seções
*/

/**
 * Add section
 * @param {object} data
 * @param {function} callback
 */
export const addSection = async (data, callback) => {
  await db
    .collection('sections')
    .add({})
    .then((docRef) => {
      docRef
        .set(
          {
            ...data,
            _sectionId: docRef.id,
            // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          },
          { merge: true }
        )
        .then(() => {
          callback && typeof callback === 'function' && callback()
        })
    })
}

/**
 * Busca a coleção "sections" by "order" desc
 * @param {function} callback atualizador de estado
 * @returns `Array` com a resposta do firebase
 */
export const getSections = async (callback) => {
  const sectionsSnapshot = await db
    .collection('sections')
    .orderBy('order', 'asc')
    .get()
    .catch(function (error) {
      console.error('getSections ops... ', error)
    })
  const sections = sectionsSnapshot.docs.map((doc) => doc.data())
  callback && typeof callback === 'function' && callback(sections)

  return sections
}

/**
 * Update section
 * @param {string} _sectionId Propriedade da Seção
 * @param {object} data
 * @param {function} callback
 */
export const updateSection = async (_sectionId, data, callback) => {
  // update content
  await db
    .collection('sections')
    .doc(_sectionId)
    .set(
      {
        ...data,
        // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )

  callback && typeof callback === 'function' && callback()
}

/**
 * Delete section
 * @param {string} _sectionId Propriedade da Seção
 * @param {function} callback
 */
export const deleteSection = async (_sectionId, callback) => {
  await db
    .collection('sections')
    .doc(_sectionId)
    .delete()
    .then(function () {
      callback && typeof callback === 'function' && callback()
    })
    .catch(function (error) {
      console.error('Error removing document: ', error)
    })
}

/*
 *
 *
 * ------ Produtos
 */

/**
 * Add product
 * @param {object} data
 * @param {string} _sectionId
 */
export const addProduct = async (data, _sectionId) => {
  await db
    .collection('sections')
    .doc(_sectionId)
    .collection('products')
    .add({})
    .then((docRef) => {
      docRef.set(
        {
          ...data,
          _sectionIdRef: _sectionId,
          _productId: docRef.id,
          // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        },
        { merge: true }
      )
    })
    .catch((error) => console.error('error adding product', error))
}

/**
 * Observe a list of products for a respective section
 * @param {string} sectionId Section Id to make a query for the document
 * @param {function} callback setFunction
 * @returns {function} Cleaning function: unsubscribeProductsBySection
 */
export const observeProductsBySection = (sectionId, callback) => {
  const unsubscribeProductsBySection = db
    .collection('sections')
    .doc(sectionId)
    .collection('products')
    .orderBy('order')
    .onSnapshot((querySnapshot) => {
      let products = []
      querySnapshot.forEach((doc) => products.push(doc.data()))
      callback(products)
    })

  return unsubscribeProductsBySection
}

/**
 * Busca todos os produtos
 * @param {function} callback atualizador de estado
 * @returns Objeto com a resposta do firebase
 */
export const getProducts = async (callback) => {
  const products = []
  await db
    .collectionGroup('products')
    .orderBy('order')
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        products.push(doc.data())
      })
    })
    .catch(function (error) {
      console.log('getProducts ops... ', error)
    })

  callback && typeof callback === 'function' && callback(products)

  return products
}

/**
 * Update product
 * @param {object} data content to update
 * @param {object} productSelected original content
 */
export const updateProduct = async (data, productSelected) => {
  await db
    .collection('sections')
    .doc(productSelected._sectionIdRef)
    .collection('products')
    .doc(productSelected._productId)
    .set(
      {
        ...data,
        // updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true }
    )
    .catch(function (error) {
      console.error('updateProduct ops... ', error)
    })
}

/**
 * Delete product
 * @param {object} productSelected product object that will be deleted
 */
export const deleteProduct = async (productSelected) => {
  await db
    .collection('sections')
    .doc(productSelected._sectionIdRef)
    .collection('products')
    .doc(productSelected._productId)
    .delete()
    .catch(function (error) {
      console.error('Error removing document: ', error)
    })
}

/**
 * Change product section
 * @param {object} originalData
 * @param {object} newData
 * @param {string} sectionId
 */
export const changeProductSection = async (originalData, newData, sectionId) => {
  await addProduct(newData, sectionId)
  await deleteProduct(originalData)
}
