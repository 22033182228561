import { useContext } from 'react'

import { AdminContext } from './AdminContext'
import Section from './Section'

export default function SectionsBlock() {
  const { sections } = useContext(AdminContext)

  return (
    <div>
      {sections &&
        sections.map((section) => <Section key={section._sectionId} section={section} />)}
    </div>
  )
}
