import { useState } from 'react'
import styled from 'styled-components'

import Header from '../common/Header'
import { AdminProvider } from './AdminContext'
import EditBlock from './EditBlock'
import NewProduct from './NewProduct'
import SectionModal from './SectionModal'
import SectionsBlock from './SectionsBlock'

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: var(--bk);
  padding-bottom: 2rem;
`

const Inner = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 1rem;
`

const Main = styled.main`
  display: grid;
  grid-template-columns: minmax(300px, 2fr) minmax(400px, 1fr);
  align-items: start;
  grid-gap: 1rem;
  margin-bottom: 1rem;
`

export default function AdminPage() {
  const [showNewProduct, setShowNewProduct] = useState(false)
  const [showSectionModal, setShowSectionModal] = useState(false)

  return (
    <AdminProvider>
      <Wrapper>
        <Inner>
          <Header />
          <nav style={{ marginBottom: '1rem' }}>
            <button onClick={() => setShowSectionModal(true)}>Nova Seção</button>
            <button onClick={() => setShowNewProduct(true)}>Novo Produto</button>
          </nav>
          {showSectionModal && (
            <SectionModal setShowSectionModal={setShowSectionModal} curSection={false} />
          )}
          {showNewProduct && <NewProduct setShowNewProduct={setShowNewProduct} />}
          <Main>
            <SectionsBlock />
            <EditBlock />
          </Main>
        </Inner>
      </Wrapper>
    </AdminProvider>
  )
}
