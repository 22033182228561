import { useContext } from 'react'

import { AuthContext } from './common/AuthContext'

import Header from './common/Header'
import IconGoogle from '../icons/Google'
import styled from 'styled-components'

const Content = styled.main`
  padding: 2rem 1rem;
  min-height: 80vh;
  display: grid;
  place-content: center;

  h1 {
    font-size: 1.5rem;
    text-align: center;
    color: var(--tp-coral);
  }
`

const Button = styled.button`
  font-family: inherit;
  font-size: 1.2rem;
  background-color: var(--gray6);
  border: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 0.5rem 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: var(--radius-sm);
  cursor: pointer;

  svg {
    margin-right: 0.5em;
    flex-shrink: 0;
  }

  span {
    padding-bottom: 2px;
  }
`

export default function Login() {
  const { signInWithGoogle } = useContext(AuthContext)

  return (
    <div>
      <Header />
      <Content>
        <h1>Faça login para acessar sua conta e realizar pedidos ✨</h1>
        <Button onClick={signInWithGoogle}>
          <IconGoogle />
          <span>Acessar com Google</span>
        </Button>
      </Content>
    </div>
  )
}
