import { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'

import { AuthContext } from '../common/AuthContext'
import { CartContext } from '../cart/CartContext'
import formatPrice from '../../functions/formatPrice'
import IconWhatsapp from '../../icons/Whatsapp'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  background-color: var(--bk);
  padding: 1rem;
  width: 100%;
  max-width: var(--max-width);
  margin: 0 auto;
  border-top: 1px solid var(--gray5);

  display: flex;
`

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  border: none;
  font-size: inherit;
  box-shadow: none;

  padding: 0.75rem;
  background-color: var(--tp-verde);
  color: white;
  border-radius: var(--radius);
  cursor: pointer;

  /* zap */
  > :first-child {
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* price */
  > :last-child {
    margin-left: auto;
  }

  &:disabled {
    opacity: 0.5;
    cursor: initial;
  }
`

function mountMessage(cart, fullPrice) {
  const greeting = `Olá, Luciana! Este é o meu pedido:`

  const order = cart.reduce((acc, product) => {
    return (
      acc +
      `➜ ${product.qtd} x *${product.title} (${product.amount})*\n${formatPrice(
        product.price,
        product.qtd
      )}${product.obs ? '\nObs. ' + product.obs : ''}\n\n———\n\n`
    )
  }, '')

  return `${greeting}\n\n${order}*Total:* ${formatPrice(fullPrice)}`
}

export default function Checkout() {
  const { cart, setCart } = useContext(CartContext)
  const { userData } = useContext(AuthContext)
  const [canBuy, setCanBuy] = useState(false)

  const fullPrice = cart.reduce((acc, product) => acc + product.qtd * product.price, 0)

  function handleCheckout() {
    const message = mountMessage(cart, fullPrice)
    const messageUrlEncoded = encodeURIComponent(message)
    window.open(`https://wa.me/5548991503351?text=${messageUrlEncoded}`, '_blank')
    setCart([])
  }

  useEffect(() => {
    setCanBuy(() => cart?.length)
  }, [cart, userData])

  return (
    <Wrapper>
      <Button onClick={handleCheckout} disabled={!canBuy}>
        <span>
          <IconWhatsapp style={{ fill: 'white' }} />
        </span>
        {canBuy ? <span>Enviar Pedido</span> : <span>Sacola está vazia</span>}
        <span>{formatPrice(fullPrice)}</span>
      </Button>
    </Wrapper>
  )
}
