import { useEffect, useState } from 'react'
import styled from 'styled-components'

import formatPrice from '../../functions/formatPrice'

import BackButton from '../common/BackButton'
import Header from '../common/Header'
import AddCart from './AddCart'
import AutoTextArea from '../common/AutoTextArea'

const Wrapper = styled.main`
  margin: 0.5rem 1rem;
  position: relative;
  display: grid;
  grid-row-gap: 0.5rem;
  padding-bottom: 5rem;
`

const Image = styled.img`
  width: 100%;
  min-height: 3rem;
  background-color: var(--gray5);
  max-height: 100vw;
  object-fit: cover;
  border-radius: 10px;
  display: block;
  margin: 0;
`

const Title = styled.h1`
  font-size: 1.5rem;
  margin: 0;
`

const Price = styled.p`
  margin: 0;
  margin-top: auto;
  font-size: 1.2rem;
  color: var(--tp-verde);
  /* font-weight: bold; */
`

const Description = styled.p`
  margin: 0;
`

const ExtraInfos = styled.p`
  font-size: 0.9rem;
  color: var(--gray3);
  line-height: 1.3;
  margin: 0;
`

const Notes = styled.div`
  textarea {
    display: block;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0;
    padding: 0.5rem;

    font-family: inherit;
    font-size: inherit;
    line-height: 1.4;
    color: var(--gray3);

    background-color: white;
    border: 1px solid var(--gray3);
    border-radius: var(--radius);

    :focus {
      outline: none;
      box-shadow: inset 0 0 0 1px var(--tp-marrom);
      border-color: var(--tp-marrom);
    }
  }
`

export default function ProductPage({ product }) {
  const { image, title, price, amount, description, ingredients, allergies } = product

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [notes, setNotes] = useState('')

  const paragraphs = description.split(/\r?\n|\r/g).filter((p) => p !== '')

  return (
    <>
      <Header />
      <Wrapper>
        <Image src={image} />
        <BackButton style={{ position: 'absolute', top: '0.5rem', left: '0.5rem' }} />
        <Title>{title}</Title>
        <Price>
          {formatPrice(price)} – {amount}
        </Price>
        {paragraphs.map((paragraph, i) => (
          <Description key={i}>{paragraph}</Description>
        ))}
        {ingredients && (
          <ExtraInfos>
            <strong>Ingredientes: </strong>
            {ingredients}
          </ExtraInfos>
        )}
        {allergies && (
          <ExtraInfos>
            <strong>Alergênicos: </strong>
            {allergies}
          </ExtraInfos>
        )}
        <Notes>
          <label>
            Observações:
            <AutoTextArea onChange={(event) => setNotes(event.target.value)} />
          </label>
        </Notes>
      </Wrapper>
      <AddCart product={product} notes={notes} />
    </>
  )
}
