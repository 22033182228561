import { useEffect, useRef } from 'react'
import styled from 'styled-components'
import ScrollspyNav from 'react-scrollspy-nav'

import { useScroll } from '../../functions/useScroll'
import slugify from '../../functions/slugify'

const Nav = styled.nav`
  background-color: var(--tp-bege);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);

  ul {
    display: flex;
    overflow-x: auto;
    padding-left: 1rem;
    margin: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    display: block;
    white-space: nowrap;
    text-decoration: none;
    color: var(--tp-coral);
    margin-right: 0.5rem;
    padding: 0.5rem 0.5rem 0.5rem 0;
  }

  .is-current,
  .is-current a {
    color: var(--tp-marrom);
  }
`

// TODO warning de tentar mexer no state com o componente desmontado

export default function AnchorNav({ sections }) {
  const navEl = useRef(null)
  const ulEl = useRef(null)
  const { scrollY } = useScroll()

  const sectionSlugs = sections?.reduce(
    (acc, section) => [...acc, slugify(section.name)],
    []
  )

  function scrolledLinks() {
    const linkEls = [...document.getElementsByClassName('nav-link')]
    const indexActive = linkEls.findIndex((aEl) => aEl.classList.contains('is-current'))
    return indexActive !== -1 ? linkEls.slice(0, indexActive) : []
  }

  function scrollNavItems(scrolledItems) {
    const oneRem = parseFloat(getComputedStyle(document.documentElement).fontSize)
    const scrooledTotalWidth = scrolledItems.reduce((acc, link) => {
      const linkWidth = link.getBoundingClientRect().width
      return acc + linkWidth + oneRem / 2
    }, 0)

    ulEl.current?.scrollTo({
      left: scrooledTotalWidth,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const oldLinks = scrolledLinks()
    scrollNavItems(oldLinks)
  }, [scrollY])

  return (
    <Nav ref={navEl}>
      {sections && sectionSlugs && (
        <ScrollspyNav
          scrollTargetIds={sectionSlugs}
          offset={-60}
          activeNavClass="is-current"
          scrollDuration="100"
          headerBackground="true"
        >
          <ul ref={ulEl}>
            {sections.map(({ name }) => (
              <li key={name}>
                <a href={`#${slugify(name)}`} className="nav-link">
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </ScrollspyNav>
      )}
    </Nav>
  )
}
