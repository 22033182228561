import { createContext, useState, useEffect } from 'react'

import { getSections } from '../../services/firestore'

export const AdminContext = createContext()

export function AdminProvider({ children }) {
  const [sections, setSections] = useState(null)
  const [productSelected, setProductSelected] = useState(null)

  useEffect(() => {
    if (!sections) getSections(setSections)
  }, [sections])

  const value = {
    sections,
    setSections,
    productSelected,
    setProductSelected,
  }

  return <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
}
