import styled from 'styled-components'

import slugify from '../../functions/slugify'
import Product from './Product'

const Wrapper = styled.section``

const SectionTitle = styled.h2`
  background-color: var(--bk);
  margin: 1.5rem 1rem 0rem;
`

const SectionDescription = styled.p`
  font-size: 0.9rem;
  margin: 0 1rem;
  color: var(--gray3);
`

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default function Section({ section }) {
  const { name, description, products } = section

  return (
    <Wrapper id={slugify(name)}>
      <SectionTitle>{name}</SectionTitle>
      <SectionDescription>{description}</SectionDescription>
      <CardsWrapper>
        {products && products.map((p) => <Product key={p._productId} product={p} />)}
      </CardsWrapper>
    </Wrapper>
  )
}
