import { useContext } from 'react'

import { AuthContext } from './common/AuthContext'

import Header from './common/Header'
import AdminPage from './admin/AdminPage'
import LoginPage from './LoginPage'

export default function LoginWall() {
  const { currentUser, loadingUser, isAdmin, signOut } = useContext(AuthContext)

  if (loadingUser) {
    return (
      <>
        <Header />
        <div>Carregando</div>
      </>
    )
  }

  if (currentUser) {
    if (isAdmin) {
      return <AdminPage />
    } else {
      return (
        <>
          <Header />
          <p>
            Sem permissão administrativa. <button onClick={signOut}>Sair</button>
          </p>
        </>
      )
    }
  }

  return <LoginPage />
}
