import { useForm } from 'react-hook-form'
import { useContext } from 'react'

import { AdminContext } from './AdminContext'

import { addSection, updateSection, deleteSection } from '../../services/firestore'

import { Form, InputStyled, Grid, Action } from '../common/Form_Styles'

export default function FormSection({ setShowSectionModal, curSection, sectionIsEmpty }) {
  const { register, handleSubmit, errors } = useForm()
  const { setSections } = useContext(AdminContext)

  // set defaults
  const name = curSection.name ?? ''
  const description = curSection.description ?? ''
  const order = curSection.order ?? null

  function closeAndUpdate() {
    setShowSectionModal(false)
    setSections(null)
  }

  /**
   * Form upload handler
   * @param {object} data Form data
   */
  function onSubmit(data) {
    data.order = parseInt(data.order)
    if (!curSection) {
      addSection(data, closeAndUpdate)
    } else {
      updateSection(curSection._sectionId, data, closeAndUpdate)
    }
  }

  /**
   * Prompt user to delete from database
   */
  function handleDelete() {
    if (sectionIsEmpty) {
      window.confirm(`Deletar seção "${name}"?`) &&
        deleteSection(curSection._sectionId, closeAndUpdate)
    } else {
      alert('Primeiro remova os produtos')
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid col={4}>
        <Action
          type="reset"
          onClick={() => setShowSectionModal(false)}
          style={{ backgroundColor: 'var(--tp-amarelo)' }}
        >
          Cancelar
        </Action>

        {curSection && (
          <Action
            type="button"
            onClick={handleDelete}
            style={{ backgroundColor: 'var(--tp-coral)' }}
          >
            Excluir
          </Action>
        )}

        <Action
          type="submit"
          style={{ backgroundColor: 'var(--tp-verde)', gridColumn: '4' }}
        >
          Salvar
        </Action>
      </Grid>

      <label>
        Título da Seção
        <InputStyled
          type="text"
          name="name"
          defaultValue={name}
          ref={register({ required: true })}
          error={errors.name}
        />
      </label>
      <label>
        Descrição
        <InputStyled
          type="text"
          name="description"
          defaultValue={description}
          ref={register()}
          error={errors.description}
        />
      </label>
      <Grid col={3}>
        <label>
          Ordem
          <InputStyled
            type="number"
            name="order"
            defaultValue={order}
            ref={register({ required: true })}
            error={errors.order}
          />
        </label>
      </Grid>
    </Form>
  )
}
