import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import IconBasket from '../../icons/Basket'
import { CartContext } from '../cart/CartContext'
import formatPrice from '../../functions/formatPrice'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: var(--max-width);
  padding: 0.5rem 1rem;
  background-color: var(--tp-bege);
  color: var(--tp-marrom);
  font-size: 0.9rem;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;

  display: flex;
  align-items: center;
  > div {
    flex: 1;
  }
`

const IconBox = styled.div`
  display: grid;
  grid-template-columns: auto 1fr; // create fake space to collapse div

  div {
    position: relative;
    margin-top: 5px; // visual adjust
  }

  span {
    position: absolute;
    top: -0.5em;
    right: -0.75em;
    min-width: 1.75em;
    min-height: 1.75em;
    padding: 0.1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--tp-coral);
    color: var(--tp-bege);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1;
    border: 2px solid var(--tp-bege);
    border-radius: 2rem;
  }

  svg {
    fill: var(--tp-marrom);
    font-size: 1.5em;
  }
`

export default function CartStatus() {
  const { cart } = useContext(CartContext)
  const fullPrice = cart.reduce((acc, product) => acc + product.qtd * product.price, 0)

  const navigate = useNavigate()

  if (!cart.length) return null

  return (
    <Wrapper onClick={() => navigate('/sacola')}>
      <IconBox>
        <div>
          <IconBasket />
          <span>{cart.length}</span>
        </div>
      </IconBox>
      <div style={{ textAlign: 'center' }}>Ver Sacola</div>
      <div style={{ textAlign: 'right', fontWeight: 700 }}>{formatPrice(fullPrice)}</div>
    </Wrapper>
  )
}
