// import { useState, useContext, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// import { AuthContext } from './AuthContext'
// import { useClickOutside } from '../../functions/useClickOutside'

// import IconMenu from '../../icons/Menu'
import IconTradicoes from '../../icons/Tradicoes'
// import IconGoogle from '../../icons/Google'

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  p {
    text-align: right;
    font-size: 0.8rem;
    color: var(--tp-coral);
    margin: 0;
    line-height: 1.2;
  }
`

// const MenuWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 0.25rem;
//   position: relative;
// `

// const MenuIconBox = styled.button`
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   svg {
//     fill: var(--tp-marrom);
//   }
// `

// const DropMenu = styled.div`
//   position: absolute;
//   top: 100%;
//   right: -0.25rem;
//   width: 200px;
//   padding: var(--radius-sm) 0;
//   overflow: hidden;
//   z-index: 9;

//   background-color: white;
//   border-radius: 10px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

//   > * {
//     margin: 0;
//     padding: 0.75rem;
//     display: block;
//     width: 100%;
//   }

//   a {
//     text-decoration: none;
//     color: var(--tp-marrom);
//     transition: background-color 0.2s ease;

//     :focus,
//     :hover {
//       background-color: rgba(0, 0, 0, 0.08);
//     }
//   }
// `

// const Button = styled.button`
//   font-family: inherit;
//   font-size: inherit;
//   text-align: left;
//   color: var(--tp-marrom);
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   transition: background-color 0.2s ease;

//   :focus,
//   :hover {
//     background-color: rgba(0, 0, 0, 0.08);
//   }
// `

// const Loggout = styled(Button)`
//   color: var(--tp-coral);
// `

// const Loggin = styled(Button)`
//   display: flex;
//   align-items: center;
//   color: var(--tp-verde);
//   svg {
//     margin-right: 0.5em;
//     flex-shrink: 0;
//   }
// `

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
`

const ContactButton = styled.a`
  display: block;
  background-color: var(--tp-verde);
  color: white;
  padding: 0.3em 0.5em 0.4em;
  margin-left: 0.5rem;
  text-decoration: none;
  border-radius: var(--radius);
  font-size: 0.8rem;
`

export default function Header() {
  // const { currentUser, signInWithGoogle, signOut, isAdmin } = useContext(AuthContext)

  // const [showMenu, setShowMenu] = useState(false)
  // const dropMenuRef = useRef(null)
  // useClickOutside(dropMenuRef, () => setShowMenu(false))

  const messageUrlEncoded = encodeURIComponent(
    'Olá! Estou vendo seu cardápio e tenho algumas dúvidas:\n\n\n'
  )
  const contactLink = `https://wa.me/5548991503351?text=${messageUrlEncoded}`

  return (
    <Wrapper>
      <Link to="/">
        <IconTradicoes size={0.8} />
      </Link>
      <InfoWrapper>
        <p>
          Tudo <strong>sem glúten</strong>
          <br />e <strong>sem lactose</strong>
        </p>
        <ContactButton href={contactLink} target="_blank" rel="noopener">
          Tire suas Dúvidas
        </ContactButton>
      </InfoWrapper>
      {/* <MenuWrapper ref={dropMenuRef}>
        <MenuIconBox onClick={() => setShowMenu((show) => !show)}>
          <IconMenu size="1.25rem" />
        </MenuIconBox>

        {showMenu && (
          <DropMenu>
            {currentUser &&
              (isAdmin ? (
                <Link to="/admin">Admin</Link>
              ) : (
                <Link to="/minha-conta">Minha Conta</Link>
              ))}
            {currentUser ? (
              <Loggout onClick={signOut}>Sair</Loggout>
            ) : (
              <Loggin onClick={signInWithGoogle}>
                <IconGoogle />
                <span>Entrar</span>
              </Loggin>
            )}
          </DropMenu>
        )}
      </MenuWrapper> */}
    </Wrapper>
  )
}
