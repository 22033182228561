export default function IconMinus({ size = '1em', style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 124 124"
      version="1.1"
      viewBox="0 0 124 124"
      xmlSpace="preserve"
      width={size}
      height={size}
      style={style}
    >
      <path d="M112 50H12C5.4 50 0 55.4 0 62s5.4 12 12 12h100c6.6 0 12-5.4 12-12s-5.4-12-12-12z"></path>
    </svg>
  )
}
