import { useClickOutside } from '../../functions/useClickOutside'
import { useRef } from 'react'
import styled from 'styled-components'
import FormSection from './FormSection'

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  z-index: 99;

  /* centralize modal */
  display: grid;
  grid-template-rows: minmax(2rem, 1fr) auto minmax(2rem, 1fr);
  grid-template-columns: minmax(300px, 600px);
  justify-content: center;
  ::before,
  ::after {
    content: '';
  }
`

const Modal = styled.div`
  background-color: white;
  position: relative;
  margin: 1rem;
  padding: 1rem;
  border-radius: var(--radius);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`

export default function SectionModal({
  setShowSectionModal,
  curSection,
  sectionIsEmpty = true,
}) {
  const modalRef = useRef(null)
  useClickOutside(modalRef, () => setShowSectionModal(false))

  return (
    <ModalWrapper>
      <Modal ref={modalRef}>
        <FormSection
          setShowSectionModal={setShowSectionModal}
          curSection={curSection}
          sectionIsEmpty={sectionIsEmpty}
        />
      </Modal>
    </ModalWrapper>
  )
}
