import { useState, useEffect, useRef, forwardRef } from 'react'

function mergeRefs(...refs) {
  const filteredRefs = refs.filter(Boolean)
  if (!filteredRefs.length) return null
  if (filteredRefs.length === 0) return filteredRefs[0]
  return (inst) => {
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst)
      } else if (ref) {
        ref.current = inst
      }
    }
  }
}

/**
 * Renders an autoresizable element, by default a textarea, but can receive a custom component
 * @param {any} props
 * Accept as props:
 * - Component: component to be rendered in place of "textarea" html tag
 * - onChange: callback function that receives input onChange event
 * - rows: textarea rows, 2 by default
 * - style
 * - ref
 * - ...props
 */
const AutoTextArea = forwardRef(
  (
    { Component = 'textarea', onChange = false, rows = 2, style = {}, ...props },
    forwardedRef
  ) => {
    const textAreaRef = useRef(null)
    const [text, setText] = useState(null)
    const [textAreaHeight, setTextAreaHeight] = useState('auto')

    useEffect(() => {
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight + 2}px`)
    }, [text])

    function onChangeHandler(event) {
      setTextAreaHeight('auto')
      setText(event.target.value)

      if (onChange) {
        onChange(event)
      }
    }

    return (
      <Component
        {...props}
        ref={mergeRefs(textAreaRef, forwardedRef)}
        rows={rows}
        style={{
          height: textAreaHeight,
          resize: 'none',
          ...style,
        }}
        onChange={onChangeHandler}
      />
    )
  }
)

export default AutoTextArea
