import { useContext, useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import { AdminContext } from './AdminContext'
import ProductForm from './ProductForm'

const Wrapper = styled.section`
  position: sticky;
  top: 1rem;
  max-height: 85vh;
  overflow-y: auto;

  display: grid;
  grid-gap: 0.5rem;
  background-color: white;
  padding: 0.5rem;
  border-bottom: 1px solid var(--gray5);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  border-radius: var(--radius);
`

export default function EditBlock() {
  const { productSelected } = useContext(AdminContext)
  const [updateForm, setUpdateForm] = useState(0)
  const wrapperRef = useRef(null)

  useEffect(() => {
    setUpdateForm((c) => c + 1)
    wrapperRef.current && wrapperRef.current.scrollTo(0, 0)
  }, [productSelected])

  if (productSelected === null)
    return <Wrapper>Escolha um produto para editar</Wrapper>

  return (
    <Wrapper ref={wrapperRef}>
      <ProductForm productSelected={productSelected} key={updateForm} />
    </Wrapper>
  )
}
