import { createContext, useState, useEffect } from 'react'

import { getSections, getProducts } from '../../services/firestore'

export const DataContext = createContext()

export function DataProvider({ children }) {
  const [sections, setSections] = useState(null)
  const [allProducts, setAllProducts] = useState(null)
  const [data, setData] = useState(null)

  useEffect(() => {
    getSections(setSections)
    getProducts(setAllProducts)
  }, [])

  useEffect(() => {
    if (allProducts && sections) {
      const sec = sections
        .map((s) => ({
          ...s,
          products: allProducts.filter(
            (p) => p._sectionIdRef === s._sectionId && !p.isHide
          ),
        }))
        .filter((s) => s.products.length)
      const feat = allProducts.filter((p) => p.isFeatured && !p.isHide)
      setData({
        sections: sec,
        featured: feat,
      })
    }
  }, [allProducts, sections])

  const value = {
    data,
    allProducts,
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
