import styled, { keyframes } from 'styled-components'

import IconTradicoes from '../../icons/Tradicoes'
const pulse = keyframes`

  from {
    opacity: 0.3;
  }

  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  display: grid;
  place-content: center;
  grid-gap: 1rem;
  background-color: var(--tp-bege);

  height: 100vh;
  text-align: center;
`

const Inner = styled.div`
  animation: ${pulse} 1.7s linear infinite alternate-reverse;
`

export default function LoadingOverlay() {
  return (
    <Wrapper>
      <Inner>
        <IconTradicoes />
      </Inner>
    </Wrapper>
  )
}
