import { useState, useEffect } from 'react'

import { firebaseStorage } from '../services/firestore'

function normalizeFileName(text) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w-.]+/g, '')
    .replace(/--+/g, '-')
}

function generateUniqueFileName(fileName) {
  const replacement = `-${Math.random().toString(36).slice(6)}.`
  return fileName.replace(/\.([^.]*)$/, replacement + '$1')
}

export const useFirebaseStorage = () => {
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(null)
  const [error, setError] = useState(null)
  const [url, setUrl] = useState(null)

  // runs every time the file value changes
  useEffect(() => {
    if (file) {
      // handle name
      const newName = normalizeFileName(file.name)
      const uniqueName = generateUniqueFileName(newName)
      const newFile = new File([file], uniqueName, { type: file.type })

      // storage ref
      const storageRef = firebaseStorage.ref(newFile.name)

      storageRef.put(newFile).on(
        'state_changed',
        (snap) => {
          // track the upload progress
          let percentage = Math.round(
            (snap.bytesTransferred / snap.totalBytes) * 100
          )
          setProgress(percentage)
        },
        (err) => {
          setError(err)
        },
        async () => {
          // get the public download img url
          const downloadUrl = await storageRef.getDownloadURL()

          // save the url to local state
          setUrl(downloadUrl)
        }
      )
    }
  }, [file])

  return { setFile, progress, url, error, file }
}
