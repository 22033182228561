import { useEffect, useState, useContext } from 'react'
import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { DataContext } from '../common/DataContext'
import ProductPage from './ProductPage'
import LoadingOverlay from '../common/LoadingOverlay'
import Header from '../common/Header'

const FrontWrapper = styled.div`
  max-width: var(--max-width);
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--bk);

  /* enquanto n tem mobile */
  box-shadow: 400px 0 0 #aaaaaa, 800px 0 0 #aaaaaa, 1200px 0 0 #aaaaaa, -400px 0 0 #aaaaaa,
    -800px 0 0 #aaaaaa, -1200px 0 0 #aaaaaa;
`

const ErrorWrapper = styled.div`
  margin: 1rem;
  display: grid;
  place-content: center;
  grid-gap: 1rem;
  min-height: 70vh;

  p {
    margin: 0;
    font-size: 1.25rem;
    text-align: center;
  }

  a {
    text-align: center;
    color: var(--tp-coral);
    text-decoration: none;
    border: 1px solid var(--tp-coral);
    border-radius: var(--radius);
    padding: 0.25rem;
  }
`

export default function ProductRouting() {
  const { allProducts } = useContext(DataContext)
  const [product, setProduct] = useState(null)
  const [error, setError] = useState(false)
  const location = useLocation()

  useEffect(() => {
    if (allProducts) {
      const slug = location.pathname.replace('/produto/', '').replace('/', '')
      const prod = allProducts.filter((p) => p.slug === slug)[0]
      if (prod) {
        setProduct(prod)
      } else {
        setError(true)
      }
    }
  }, [location, allProducts])

  if (error) {
    return (
      <FrontWrapper>
        <Header />
        <ErrorWrapper>
          <p>Ops 😅 Produto não encontrado</p>
          <Link to="/">Voltar ao cardápio</Link>
        </ErrorWrapper>
      </FrontWrapper>
    )
  }

  return (
    <FrontWrapper>
      {product ? <ProductPage product={product} /> : <LoadingOverlay />}
    </FrontWrapper>
  )
}
