import { useContext } from 'react'
import styled from 'styled-components'

import IconStar from '../../icons/Star'
import formatPrice from '../../functions/formatPrice'
import { AdminContext } from './AdminContext'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 0.5fr) auto minmax(auto, 10fr) 2fr;
  grid-gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  padding: 0.5rem 1rem;
  background-color: white;
  border-bottom: 1px solid var(--gray5);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 0.25rem;

  img {
    width: 80px;
    min-width: 80px;
    height: 80px;
    min-height: 80px;
    object-fit: cover;
    border-radius: var(--radius);
    background-color: var(--gray6);
  }

  &.hide {
    opacity: 0.6;
    text-decoration: line-through;
  }
`

const Icons = styled.div`
  display: grid;
  place-items: center;

  svg {
    fill: var(--gray4);
  }

  .featured & svg {
    fill: var(--tp-amarelo);
  }
`

const Title = styled.div`
  font-weight: 700;
`

const Details = styled.div`
  white-space: nowrap;

  p {
    margin: 0;
    text-align: right;
  }
`

export default function ListItem({ product }) {
  const { setProductSelected } = useContext(AdminContext)

  const {
    //slug,
    image,
    title,
    price,
    amount,
    isFeatured,
    isHide,
  } = product

  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') setProductSelected(product)
  }

  return (
    <Wrapper
      onClick={() => setProductSelected(product)}
      onKeyDown={handleKeyDown}
      tabIndex="0"
      className={`${isFeatured && 'featured'} ${isHide && 'hide'}`}
    >
      {/* <Icons style={{ padding: '0.5rem' }}>≡</Icons> */}
      <Icons>
        <IconStar />
      </Icons>
      <img src={image} alt={title} />
      <Title>
        <p>{title}</p>
      </Title>
      <Details>
        <p>{formatPrice(price)}</p>
        <p>{amount}</p>
      </Details>
    </Wrapper>
  )
}
