import styled from 'styled-components'

import FeaturedProduct from './FeaturedProduct'

const CardsWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 1rem;
`

const SectionTitle = styled.h2`
  margin: 1.5rem 1rem 0rem;
`

/*
TODO

- encontrar um troço pra fazer isso bonitinho e crossbrowser, alguma lib ou npm
  - sla se pa ta bom viu
  - ta rodei no browser stack e n tá muito não

*/

export default function Featured({ products }) {
  if (!products?.length) return null
  return (
    <section>
      <SectionTitle>Destaques</SectionTitle>
      <CardsWrapper>
        {products.map((prod) => (
          <FeaturedProduct key={prod._productId} product={prod} />
        ))}
        {/* horizontal spacer */}
        <div style={{ minWidth: '1rem' }}></div>
      </CardsWrapper>
    </section>
  )
}
