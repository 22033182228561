import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { observeProductsBySection } from '../../services/firestore'

import ListItem from './ListItem'
import SectionModal from './SectionModal'
import IconEdit from '../../icons/Edit'

const Wrapper = styled.section`
  margin-bottom: 2rem;
`

const Header = styled.header`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  margin-bottom: 1rem;

  h2,
  p {
    margin: 0;
  }

  p {
    font-size: 0.9rem;
    color: var(--gray3);
  }
`

const Actions = styled.div`
  grid-column: 2/3;
  grid-row: 1;
  justify-self: center;
  align-self: center;
  display: flex;
`

const Action = styled.button`
  background-color: unset;
  border: unset;

  padding: 0.4rem;
  display: grid;
  place-content: center;
  cursor: pointer;
  svg {
    fill: var(--gray3);
  }
  :focus,
  :hover {
    svg {
      fill: var(--gray2);
    }
  }
`

export default function Section({ section }) {
  const { _sectionId, name, description } = section
  const [productsObserved, setProductsObserved] = useState(null)
  const [showSectionModal, setShowSectionModal] = useState(false)

  useEffect(() => {
    const unsubscribeProductsBySection = observeProductsBySection(
      _sectionId,
      setProductsObserved
    )
    return () => {
      console.log('unsubscribeProductsBySection')
      unsubscribeProductsBySection()
    }
  }, [_sectionId])

  return (
    <Wrapper key={name}>
      <Header>
        <h2>{name}</h2>
        <p>{description}</p>
        <Actions>
          <Action onClick={() => setShowSectionModal(true)}>
            <IconEdit size="1rem" />
          </Action>
        </Actions>
      </Header>
      {showSectionModal && (
        <SectionModal
          setShowSectionModal={setShowSectionModal}
          curSection={section}
          sectionIsEmpty={productsObserved?.length === 0}
        />
      )}
      <div>
        {productsObserved &&
          productsObserved.map((product) => (
            <ListItem key={product.slug} product={product} />
          ))}
        {productsObserved === null && <div>Carregando</div>}
        {productsObserved?.length === 0 && <div>Sem produtos</div>}
      </div>
    </Wrapper>
  )
}
