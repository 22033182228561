import styled from 'styled-components'

import Section from './Section'

const Wrapper = styled.div`
  padding-bottom: 4rem;
`

export default function Sections({ sections }) {
  return (
    <Wrapper>
      {sections && sections.map((s) => <Section key={s._sectionId} section={s} />)}
    </Wrapper>
  )
}
