import { useContext } from 'react'
import styled from 'styled-components'

import { DataContext } from '../common/DataContext'

import Header from '../common/Header'
import AnchorNav from './AnchorNav'
import Featured from './Featured'
import Sections from './Sections'
import CartStatus from '../common/CartStatus'
import LoadingOverlay from '../common/LoadingOverlay'

const Sticky = styled.div`
  position: sticky;
  top: 0;
  background-color: var(--bk);
  z-index: 99;
`

const FrontWrapper = styled.main`
  max-width: var(--max-width);
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--bk);

  /* enquanto n tem mobile */
  box-shadow: 400px 0 0 #aaaaaa, 800px 0 0 #aaaaaa, 1200px 0 0 #aaaaaa, -400px 0 0 #aaaaaa,
    -800px 0 0 #aaaaaa, -1200px 0 0 #aaaaaa;
`

export default function Home() {
  const { data } = useContext(DataContext)

  return (
    <FrontWrapper>
      {data ? (
        <>
          <Sticky>
            <Header />
            <AnchorNav sections={data.sections} />
          </Sticky>
          <Featured products={data.featured} />
          <Sections sections={data.sections} />
          <CartStatus />
        </>
      ) : (
        <LoadingOverlay />
      )}
    </FrontWrapper>
  )
}
