import styled from 'styled-components'
import { Link } from 'react-router-dom'

import formatPrice from '../../functions/formatPrice'

const Card = styled(Link)`
  min-width: calc(50% - 2rem);
  margin: 1rem;
  margin-right: 0;
  box-shadow: 0 1px 2px hsla(0, 0%, 0%, 0.1);
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  text-decoration: unset;
  color: inherit;

  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: calc(9 / 16 * 100%);
  cursor: pointer;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Content = styled.div`
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
`

const Title = styled.h3`
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  line-height: 1.2;
`

const Description = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  font-size: 0.95rem;
  color: var(--gray3);
`

const Price = styled.p`
  color: var(--tp-verde);
  margin: 0;
  margin-top: auto;
  font-size: 0.95rem;
`

export default function FeaturedProduct({ product }) {
  const { _productId, image, title, excerpt, slug, amount, price } = product

  return (
    <Card key={_productId} to={`produto/${slug}`}>
      <ImageWrapper>
        <img src={image} alt="" />
      </ImageWrapper>
      <Content>
        <Title>{title}</Title>
        <Description>{excerpt}</Description>
        <Price>
          {formatPrice(price)} – {amount}
        </Price>
      </Content>
    </Card>
  )
}
