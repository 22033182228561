import { createContext, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

import { app, createUser, observeUser } from '../../services/firestore'

export const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null)
  const [loadingUser, setLoadingUser] = useState(false)
  const [userData, setUserData] = useState(null)

  useEffect(() => {
    setLoadingUser(true)
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user)
      setLoadingUser(false)
    })
  }, [])

  useEffect(() => {
    if (currentUser) {
      observeUser(currentUser, setUserData)
    } else {
      setUserData(null)
    }
  }, [currentUser])

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider()
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(({ user }) => createUser(user))
  }

  function signOut() {
    firebase.auth().signOut()
  }

  const isAdmin =
    currentUser?.email === 'jorgeks95@gmail.com' ||
    currentUser?.email === 'luciana.kunrath@gmail.com'

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        userData,
        loadingUser,
        signInWithGoogle,
        signOut,
        isAdmin,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
