import { useContext, useEffect } from 'react'
import styled from 'styled-components'

import { CartContext } from '../cart/CartContext'

import Header from '../common/Header'
import Checkout from './Checkout'
import BackButton from '../common/BackButton'
import CartItem from './CartItem'

const FrontWrapper = styled.div`
  max-width: var(--max-width);
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--bk);

  /* enquanto n tem mobile */
  box-shadow: 400px 0 0 #aaaaaa, 800px 0 0 #aaaaaa, 1200px 0 0 #aaaaaa, -400px 0 0 #aaaaaa,
    -800px 0 0 #aaaaaa, -1200px 0 0 #aaaaaa;
`

const Wrapper = styled.main`
  padding-bottom: 5rem;
`

const Title = styled.h1`
  margin: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
`

const CartItems = styled.div`
  margin: 1rem;

  > div:not(:last-of-type) {
    border-bottom: 1px solid var(--gray5);
  }
`

export default function CartPage() {
  const { cart } = useContext(CartContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <FrontWrapper>
      <Header />
      <Wrapper>
        <Title>
          <BackButton style={{ marginRight: '0.5rem' }} />
          Sacola
        </Title>
        <CartItems>
          {cart?.length > 0 ? (
            cart.map((product, i) => <CartItem key={product.slug} product={product} />)
          ) : (
            <p>Sacola vazia</p>
          )}
        </CartItems>
        <Checkout />
      </Wrapper>
    </FrontWrapper>
  )
}
