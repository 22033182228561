import styled from 'styled-components'
import IconBackArrow from '../../icons/BackArrow'
import useBackSafe from '../../functions/useBackSafe'

const Back = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  cursor: pointer;
`

export default function BackButton({ navigate, style = {} }) {
  const { goBackSafe } = useBackSafe()

  return (
    <Back onClick={navigate || goBackSafe} style={style}>
      <IconBackArrow style={{ fill: 'white', fontSize: '0.8em' }} />
    </Back>
  )
}
