import { useState, useContext } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'

import { Form, InputStyled, Grid, Action } from '../common/Form_Styles'
import { AdminContext } from './AdminContext'
import {
  updateProduct,
  addProduct,
  deleteProduct,
  changeProductSection,
} from '../../services/firestore'
import slugify from '../../functions/slugify'
import { useFirebaseStorage } from '../../functions/useFirebaseStorage'
import AutoTextArea from '../common/AutoTextArea'

const ImageBlock = styled.div`
  position: relative;
  width: 100%;
  min-height: 200px;
  aspect-ratio: 16 / 9;
  background-color: var(--gray6);
  border-radius: var(--radius-sm);
  overflow: hidden;
  border: 1px solid var(--gray5);
  z-index: 1;

  img {
    position: absolute;
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 0px;
  }

  span {
    position: absolute;
    display: block;
    z-index: 2;
    font-size: 0.8rem;
    line-height: 1;
    bottom: 0;
    padding: 0.25rem;
    border-radius: var(--radius-sm);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }
`

/**
 * Find respective sectionId
 * @param {object} data submited data
 * @param {array} sections
 * @return {string} sectionId
 */
function findSectionId(data, sections) {
  return sections.find((section) => section.name === data.sectionRef)._sectionId
}

/*
TODO
bug
ao editar a seção, mesmo se for pra dar match com a atual, ele limpa o estado dos produtos
... jesus do céu é muito pior se não fizer isso...
ele cria uma cópia sem os _ids que fica se replicando e é indeletável
*/

export default function ProductForm({
  productSelected,
  newProduct = false,
  setShowNewProduct,
}) {
  const {
    allergies,
    amount,
    description,
    excerpt,
    image,
    ingredients,
    order,
    price,
    sectionRef,
    title,
    // slug,
    // isFeatured,
    // isHide,
  } = productSelected

  const { sections, setProductSelected } = useContext(AdminContext)
  const { register, handleSubmit, errors } = useForm({
    defaultValues: productSelected || {},
  })
  const [submiting, setSubmiting] = useState(false)

  /**
   * Form upload handler
   * @param {object} data Form data
   */
  async function onSubmit(data) {
    console.table([data.isFeatured, data.isHide])
    setSubmiting(true)

    // convert strings to numbers
    data.order = parseInt(data.order)
    data.price = parseFloat(data.price)

    // if have a new image, add to data
    if (urlUpload) data.image = urlUpload

    if (newProduct) {
      // create slug
      data.slug = slugify(data.title)

      // create product
      await addProduct(data, findSectionId(data, sections))
      setSubmiting(false)
      setShowNewProduct(false)
    } else {
      if (productSelected.sectionRef !== data.sectionRef) {
        data.image = image || urlUpload
        data.slug = slugify(data.title)

        await changeProductSection(productSelected, data, findSectionId(data, sections))
        setProductSelected(null) // todo mantém o bug em cheque
        setSubmiting(false)
      } else {
        await updateProduct(data, productSelected)
        setSubmiting(false)
      }
    }
  }

  /**
   * Prompt users, delete from database and update UI
   */
  async function handleDelete() {
    if (window.confirm(`Deletar ${title}?`)) {
      await deleteProduct(productSelected, setProductSelected)
      setProductSelected(null)
    }
  }

  /**
   * Image upload handler
   */
  const { setFile, progress, url: urlUpload } = useFirebaseStorage()
  const handleChange = (e) => {
    let selectedFile = e.target.files[0]
    const fileTypes = ['image/png', 'image/jpg', 'image/jpeg']
    if (selectedFile) {
      if (fileTypes.includes(selectedFile.type)) {
        setFile(selectedFile)
      } else {
        setFile(null)
        alert('Imagem precisa ser em um dos seguintes formatos: png, jpg ou jpeg')
      }
    }
  }

  if (sections === null) return null
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid col={4}>
        {newProduct ? (
          <Action
            disabled={submiting}
            type="reset"
            onClick={() => setShowNewProduct(false)}
            style={{ backgroundColor: 'var(--tp-amarelo)' }}
          >
            Cancelar
          </Action>
        ) : (
          <Action
            disabled={submiting}
            type="button"
            onClick={handleDelete}
            style={{ backgroundColor: 'var(--tp-coral)' }}
          >
            Excluir
          </Action>
        )}
        <Action
          disabled={submiting}
          type="submit"
          style={{ backgroundColor: 'var(--tp-verde)', gridColumn: '4' }}
        >
          Salvar
        </Action>
      </Grid>

      <label htmlFor="inputFile">
        <ImageBlock>
          <img src={urlUpload || image} alt={title} />
          {progress && <span>Carregando: {progress}%</span>}
        </ImageBlock>
        <input
          id="inputFile"
          type="file"
          onChange={handleChange}
          style={{ paddingTop: '0.5rem' }}
        />
      </label>

      <label>
        Nome
        <InputStyled
          type="text"
          name="title"
          defaultValue={title}
          ref={register({ required: true })}
          error={errors.title}
        />
      </label>

      <Grid col={2}>
        <label>
          Preço
          <InputStyled
            type="number"
            name="price"
            step=".01"
            defaultValue={price}
            ref={register({ required: true })}
            error={errors.price}
          />
        </label>
        <label>
          Quantidade
          <InputStyled
            type="text"
            name="amount"
            defaultValue={amount}
            ref={register({ required: true })}
            error={errors.amount}
          />
        </label>
      </Grid>

      <Grid col={2}>
        <label>
          Seção
          <InputStyled
            as="select"
            name="sectionRef"
            defaultValue={sectionRef}
            ref={register({ required: true })}
            error={errors.sectionRef}
          >
            {sections.map((section) => (
              <option key={section._sectionId} value={section.name}>
                {section.name}
              </option>
            ))}
          </InputStyled>
        </label>
        <label>
          Ordem
          <InputStyled
            type="number"
            name="order"
            defaultValue={order}
            ref={register({ required: true })}
            error={errors.order}
          />
        </label>
      </Grid>

      <label>
        Resumo
        <AutoTextArea
          Component={InputStyled}
          as="textarea"
          name="excerpt"
          defaultValue={excerpt}
          ref={register()}
          error={errors.excerpt}
        />
      </label>
      <label>
        Descrição
        <AutoTextArea
          Component={InputStyled}
          as="textarea"
          name="description"
          defaultValue={description}
          ref={register()}
          error={errors.description}
        />
      </label>
      <label>
        Ingredientes
        <AutoTextArea
          Component={InputStyled}
          as="textarea"
          name="ingredients"
          defaultValue={ingredients}
          ref={register()}
          error={errors.ingredients}
        />
      </label>
      <label>
        Alergênicos
        <AutoTextArea
          Component={InputStyled}
          as="textarea"
          name="allergies"
          defaultValue={allergies}
          ref={register()}
          error={errors.allergies}
        />
      </label>

      <Grid col={2}>
        <label>
          Destaque
          <input type="checkbox" name="isFeatured" ref={register} />
        </label>
        <label>
          Oculto
          <input type="checkbox" name="isHide" ref={register} />
        </label>
      </Grid>
    </Form>
  )
}
